import { useEffect, useState } from "react";
import "./Detail.css"
import { useSearchParams } from "react-router-dom";
import { useUtils } from "../../services/Utils"
import Approval from "./Approval/Approval";
import Loader from "../Loader/Loader";

const Detail = (params) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [approval, setApproval] = useState();

    const { callApi } = useUtils();

    const GetCard = async () => {
        setApproval(null);
        const x = await callApi({
            endpoint: `WebPage/GetApprovalDetail?ticketId=${searchParams.get("ticketId")}&approvalId=${searchParams.get("approvalId")}&timestamp=${new Date().getTime()}`,
            method: "GET"
        });
        setApproval(x.Approval);
    }

    useEffect(() => {
        //setTicketId(searchParams.get("ticketId"));
        //setApprovalId(searchParams.get("approvalId"));
        GetCard();
    }, []);

    return (
        <div>
            {approval !== undefined && approval !== null ? (
                <div className="approval">
                    <Approval content={approval} handleRefresh={GetCard} />
                </div>
            ) : (
                <div>
                    <Loader />
                </div>
            )}
        </div>
    )
}

export default Detail;