import './App.css';
import Header from './components/Header/Header';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import Table from './components/Table/Table';
import Detail from './components/Detail/Detail';
import ErrorPage from './components/ErrorPage/ErrorPage';
import { loginRequest } from './config/authConfig';
import { useEffect } from 'react';

function App() {

    // Auto login    
    const { login, result, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);
    const { inProgress } = useMsal();

    useEffect(() => {
        try {
            if (error instanceof InteractionRequiredAuthError) {
                login(InteractionType.Redirect, loginRequest);
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [error]);

    return (
        <>
            <HashRouter>
                <Header />
                <div className="App">
                    <AuthenticatedTemplate>

                        <Routes>
                            <Route path="/" element={<Navigate to="/table" replace />} />
                            <Route path="/detail" element={<Detail />} />
                            <Route path="/table" element={<Table />} />
                            <Route path='/error' element={<ErrorPage />} />
                        </Routes>

                    </AuthenticatedTemplate>

                    <UnauthenticatedTemplate>
                        <div className="notifyText">
                            {
                                inProgress === InteractionStatus.None ? (
                                    <h1>Please sign-in to see your approval.</h1>
                                ) : inProgress === InteractionStatus.Logout ? (
                                    <h1>Logging you out...</h1>
                                ) : (
                                    <h1>Logging you in...</h1>
                                )
                            }
                        </div>
                    </UnauthenticatedTemplate>
                </div>
            </HashRouter>
        </>
    );
}

export default App;
