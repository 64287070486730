import "./Filter.css"
import 'react-datepicker/dist/react-datepicker.css'
import React, { useState } from "react"
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Button, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


const Filter = ({ sourceList, setFilters }) => {

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;


    const [selectedSourceList, setSelectedSourceList] = useState([]);

    const [approvalCreateDateStart, setApprovalCreateDateStart] = useState(null);
    const [approvalCreateDateEnd, setApprovalCreateDateEnd] = useState(null);

    const handleFilterChange = () => {
        setFilters({ SourceList: selectedSourceList, ApprovalStartDate: approvalCreateDateStart, ApprovalEndDate: approvalCreateDateEnd });
    }

    function CheckFilterCondition() {
        return (approvalCreateDateStart === null && approvalCreateDateEnd !== null) ||
            (approvalCreateDateStart !== null && approvalCreateDateEnd === null)
    }

    return (
        <div className="filter container">
            <Stack direction="row" spacing={2} alignItems="center">
                <Autocomplete
                    multiple
                    id="sourceFilterCheckboxes"
                    options={sourceList}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    limitTags={2}
                    onChange={(e, v) => setSelectedSourceList(v)}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option}
                        </li>
                    )}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Workflow Name" placeholder="Select workflow" />
                    )}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Approval Start"
                        slotProps={{
                            field: { clearable: true },
                            textField: {
                                error: approvalCreateDateStart === null && approvalCreateDateEnd !== null
                            },
                        }}
                        onChange={(v) => setApprovalCreateDateStart(v)}
                    />
                    <DatePicker
                        label="Approval End"
                        slotProps={{
                            field: { clearable: true },
                            textField: {
                                error: approvalCreateDateStart !== null && approvalCreateDateEnd === null
                            },
                        }}
                        onChange={(v) => setApprovalCreateDateEnd(v)}
                    />
                </LocalizationProvider>
                <div>
                    <Button
                        variant='contained'
                        disabled={CheckFilterCondition()}
                        onClick={() => handleFilterChange()}
                    >
                        Apply
                    </Button>
                </div>
            </Stack>
        </div>
    )
}

export default Filter;