import { useEffect, useState } from "react";
import "./Person.css"
import { useUtils } from "../../services/Utils";

const Person = ({ email }) => {
    const [userProfile, setUserProfile] = useState();

    const { callApi } = useUtils();

    const getUser = async ({ email }) => {
        let profile = localStorage.getItem("Person:" + email)
        if (!profile) {
            const response = await callApi({
                endpoint: `WebPage/GetUserProfile?Email=${email}`,
                method: "GET"
            });
            setUserProfile(response);
            localStorage.setItem("Person:" + email, JSON.stringify(response))
        }
        else {
            setUserProfile(JSON.parse(profile))
        }
    }

    useEffect(() => {
        getUser({ email: email });
    }, [])

    return (
        !userProfile ? (
            <span>{email}</span>
        ) : (
            <span title={userProfile.Email}>{userProfile.Name}</span>
        )
    )
}

export default Person;