import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/authConfig";
import { wflowRequest } from "../config/wflowApprovalConfig";

export const useUtils = () => {

    const { instance, accounts } = useMsal()

    const callApi = async ({ endpoint, method, body }) => {
        let token = (await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        })).idToken

        const options = {
            method: method,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body),
        }

        return fetch(wflowRequest.baseUrl + endpoint, options)
            .then(response => response.json())
    };

    return { callApi }
}