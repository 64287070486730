import "./Table.css"
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useUtils } from "../../services/Utils";
import Loader from "../Loader/Loader";
import Person from "../Person/Person";
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Filter from "./Filter/Filter";
import ErrorPage from "../ErrorPage/ErrorPage";

const Table = () => {

    const pageLoading = useRef(false);
    const [error, setError] = useState("");
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [sourceList, setSourceList] = useState([])
    const [filters, setFilters] = useState(null)
    const navigate = useNavigate();
    const { callApi } = useUtils();

    const fetchData = async () => {
        pageLoading.current = true;
        try {
            const response = await callApi({
                endpoint: `WebPage/GetMyPendingApprovalList`,
                method: "POST",
                body: {
                    "Page": currentPage,
                    "PageSize": pageSize,
                    "Filters": filters
                }
            });
            setData(response.MyPendingApprovals);
            setSourceList(response.SourceList);
            setTotalPage(Math.ceil(response.Total / pageSize));
        }
        catch (err) {
            setError("Fail to get data");
            console.log(err)
        }
        finally {
            setIsLoading(false);
            pageLoading.current = false;
        }
    };

    useEffect(() => {
        setIsLoading(true);
        setError("");
        if (!pageLoading.current) {
            fetchData();
        }     
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(1);
        setIsLoading(true);
        setError("");        
        if (!pageLoading.current) {
            fetchData();
        }
    }, [pageSize, filters]);

    const currentItems = data;

    const viewDetail = (ticketId, approvalId) => {
        navigate({
            pathname: "/detail",
            search: `?ticketId=${ticketId}&approvalId=${approvalId}`
        })
    }

    return (
        <>
            <Filter sourceList={sourceList} setFilters={setFilters} />
            {isLoading ? (
                <Loader />
            ) : (
                <div className="container">
                    {error !== "" ? (
                        <ErrorPage message={error} />
                    ) : currentItems.length > 0 ? (
                        <div id="ApprovalList">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>ApprovalDescription</th>
                                        <th>Created By</th>
                                        <th>ApprovalCreateTime</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.map(item => (
                                        <tr key={item.ApprovalId}>
                                            <td>{item.Title}</td>
                                            <td>{item.ApprovalDescription}</td>
                                            <td><Person email={item.TicketCreatedByEmail} /></td>
                                            <td>{new Date(item.ApprovalCreateTime + " UTC").toLocaleString()}</td>
                                            <td>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => viewDetail(item.SignTicketId, item.ApprovalId)}
                                                >Open</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Stack spacing={2}>
                                <Pagination
                                    page={currentPage}
                                    count={totalPage}
                                    shape="rounded"
                                    onChange={(e, v) => setCurrentPage(v)}
                                />
                            </Stack>
                        </div>
                    ) : (
                        <div className="notifyText">
                            <h1>You have no approval.</h1>
                        </div>
                    )}
                </div>
            )}
            <div className="container" style={{padding: '10px'}}>
                <a className="legacyApproval">For legacy approval like E-Stamp, please visit&nbsp;
                    <a 
                        href="https://make.powerautomate.com/environments/Default-e7b94e3c-1ad5-477d-be83-17106c6c8301/approvals/received"
                        target="_blank" 
                        style={{textDecoration: 'underline', cursor:'pointer'}}
                    >
                        here
                    </a>
                </a>                
            </div>

        </>
    )

}

export default Table;