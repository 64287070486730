import "./ErrorPage.css"

const ErrorPage = ({message}) => {

    return(
        <div className="errorContainer">
            <h1>{message}</h1>
        </div>
    )
}

export default ErrorPage;